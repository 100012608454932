/* Basic styling for header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #2a3d66; /* Dark blue background */
  color: #fff; /* White text */
  position: relative; /* Needed for positioning of children */
}

/* Logo styling */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  object-fit: contain;
  border-radius: 5px; /* Rounded corners */
  background-color: #fff; /* White background for logo */
}

.school-name {
  font-size: 1.2rem; /* Font size for school name */
  font-weight: bold; /* Bold font */
}

/* Navigation bar styling */
.nav-bar {
  position: relative; /* Needed for the drawer setup */
}

/* Menu icon for mobile view */
.menu-icon {
  font-size: 1.5rem; /* Size of the menu icon */
  cursor: pointer; /* Pointer cursor */
  display: none; /* Hidden by default, shown in mobile view */
}

/* Navigation links styling */
.nav {
  display: flex; /* Flex layout for the nav items */
  align-items: center; /* Vertically centers items */
}

.nav-links {
  display: flex; /* Flex layout for the links */
  list-style: none; /* No bullet points */
  gap: 20px; /* Space between items */
}

.nav-links li {
  position: relative; /* For potential dropdown or layering */
}

.nav-links a {
  color: #fff; /* White text color */
  text-decoration: none; /* No underline on links */
  font-size: 1rem; /* Link font size */
  display: flex; /* Flex for icon and text alignment */
  align-items: center; /* Vertically aligns the items */
}

.nav-icon {
  margin-right: 8px; /* Space between icon and text */
}

/* Mobile specific styles */
@media (max-width: 768px) {
  /* Show menu icon on smaller screens */
  .menu-icon {
    display: block; /* Show the menu icon on mobile */
  }

  .nav {
    position: fixed; /* Fixed position for drawer */
    top: 0; /* Aligns to the top */
    left: -100%; /* Start hidden off screen */
    width: 75%; /* Width of the drawer */
    height: 100%; /* Full height */
    background-color: #2a3d66; /* Same background as header */
    flex-direction: column; /* Stack the links vertically */
    justify-content: flex-start; /* Start links from the top */
    align-items: flex-start; /* Align to the left */
    padding: 20px; /* Padding inside the drawer */
    transition: left 0.3s ease-in-out; /* Smooth transition for opening */
    z-index: 1000; /* High enough to be on top */
  }

  .nav.open {
    left: 0; /* When open, align to the left */
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically in the drawer */
    gap: 15px; /* Vertical spacing */
    width: 100%; /* Full width */
  }

  /* Add a semi-transparent background when the drawer is open */
  .backdrop {
    position: fixed; /* Cover the entire viewport */
    top: 0; /* Align with top */
    left: 0; /* Align with left */
    width: 100%; /* Cover full width */
    height: 100%; /* Cover full height */
    background-color: rgba(0, 0, 0, 0.5); /* Black with transparency */
    z-index: 500; /* Layer background under the nav */
  }
}

/* Hover effects for better aesthetics */
.nav-links a:hover {
  color: #ffd700; /* Gold color on hover */
}
