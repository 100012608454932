.footer-container {
  background-color: #2a3d66;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
}

.footer-content {
  display: flex;
  max-width: 1200px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.footer-details,
.contact-form {
  flex: 1;
  min-width: 300px;
  box-sizing: border-box;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-details p {
  margin: 5px 0;
}

.footer-details a {
  color: #ffd700;
  text-decoration: none;
}

.direction-button,
.social-icon {
  margin-top: 10px;
  display: inline-block;
}

.direction-button {
  background-color: #ffd700;
  color: #2a3d66;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.direction-button:hover {
  background-color: #f0e68c;
}

.social-media-buttons {
  display: flex;
  gap: 10px;
}

.social-icon {
  width: 30px;
  height: 30px;
  font-size: 24px;
  color: #fff;
  transform: scale(1);
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #ffd700;
  transform: scale(1.1);
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contact-form button {
  background-color: #ffd700;
  color: #2a3d66;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #f0e68c;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-details,
  .contact-form {
    width: 100%;
    padding: 0 20px;
  }

  .direction-button {
    width: auto;
    text-align: center;
  }
}
