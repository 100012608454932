.books-main-container {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.books-heading {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #2a3d66;
  padding: 0 10px;
}

.details-link {
  margin-bottom: 10px;
}

.details-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}

.details-link a:hover {
  text-decoration: underline;
}

.attention-message {
  background-color: #ffeb3b;
  color: #000;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center;
}

.books-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 0 10px;
}

.class-section {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.class-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.class-section h2 {
  color: #333;
  margin-bottom: 15px;
}

.subject-item {
  list-style: none;
  margin-bottom: 10px;
}

.subject-item h3 {
  color: #555;
  margin: 0 0 5px 0;
}

@media (max-width: 768px) {
  .books-heading {
    font-size: 1.8rem;
  }

  .books-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .books-main-container {
    padding: 15px;
  }

  .books-heading {
    font-size: 1.5rem;
  }

  .class-section {
    padding: 10px;
  }
}
