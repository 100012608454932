/* App.css */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
main,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}
html {
  scroll-behavior: smooth;
}

footer {
  background-color: #2a3d66;
  color: white;
  padding: 20px;
  text-align: center;
}
